var PANEL_MINIMIZED = 'minimized';
var PANEL_HIDDEN = 'hidden';
var PANEL_CLOSED= 'closed';
var PANEL_OPEN = 'open';

var $self,
  self = this,
  currentTrack,
  panelState = PANEL_CLOSED,
  previousState = PANEL_CLOSED,
  currentVolume = 100,
  playlistDict = {};
var $playBtn, mobileHeightOffSet = 0;
var playerInitialized = false;

var volObj = { volume:100, speed: 0.5 },
  volSlider, progSlider;


var initSoundManager = function(callback) {
  //Need to disable mp3 requirement to handle cases where flash is not installed
  soundManager.audioFormats.mp3.required = false;
  soundManager.setup({
    url: '/assets/',
    // optional: use 100% HTML5 mode where available
    preferFlash: false,
    useHTML5Audio: true,
    onready: function() {
      console.log('SoundManager Initialized');
      if (callback && typeof callback === 'function')
        callback.call();
    },
    ontimeout: function() {
      // Hrmm, SM2 could not start. Missing SWF? Flash blocked? Show an error, etc.?
      console.warn('SoundManager Initialization Failed');
    }
  });

  soundManager.debugMode = true;
  // soundManager.reboot();
  return soundManager;
};

var initUI = function() {
  // initialize play / pause btn
  $playBtn = $('.ap-play').find('.play-btn');
  $('.ap-play').on('click', function(){
    togglePlayPause($playBtn);
  }).on('keypress', function(e){
    if(e.which==13 || e.which==32) {
      e.preventDefault();
      togglePlayPause($playBtn);
    }
  });
  // initialize next + prev btns
  $('.ap-next').on('click', nextTrack);
  $('.ap-previous').on('click', prevTrack);
  // init MINBAR + playlist toggle btn
  $('.ap-minbar').on('click', toggleHidePlayer );
  $('.ap-minbar').on('keypress', function(e){
    if(e.which == 13 || e.which == 32){
      e.preventDefault();
      toggleHidePlayer();
    }
  });
  $('#ap-list-btn').on('click', togglePlaylist );
  $('#ap-list-btn').on('keypress', function(e){
    if(e.which == 13 || e.which == 32){
      e.preventDefault();
      togglePlaylist();
    }
  });
  // initialize jqueryui PROGRESS slider
  progSlider = $('#ap-progress-slider').slider({
    min: 0,
    max: 100,
    value: 0,
    range: 'min',
    slide: function(event, ui) {
      var timePercentage = ui.value/100;
      var duration = playlistDict[currentTrack].soundRef.duration;
      playlistDict[currentTrack].soundRef.setPosition( duration * timePercentage );
    }
  });
  // initialize jqueryui VOLUME slider
  volObj.volume = 100;
  volSlider = $('#ap-volume-slider').slider({
    min: 0,
    max: 100,
    value: volObj.volume,
    range: 'min',
    change: function(event, ui) {
      currentVolume = ui.value;
      if( playlistDict[currentTrack] ) {
        playlistDict[currentTrack].soundRef.setVolume(ui.value);
      }
    }
  });
  // make playlist sortable
  $( '#ap-playlist' ).sortable();
  $( '#ap-playlist' ).disableSelection();

  // enable volume btns
  $('#ap-vol-off').on('mousedown', function(){
    animVolume(0);
  }).on('mouseup', function(){
    // animVolume(volObj.volume);
    volObj.tween.kill();
  }).on('keydown', function(e){
    if(e.which==13 || e.which==32){
      e.preventDefault();
      animVolume(0);
    }
  }).on('keyup', function(e){
    if(e.which==13 || e.which==32){
      e.preventDefault();
      // animVolume(volObj.volume);
      volObj.tween.kill();
    }
  });

  $('#ap-vol-hi').on('mousedown', function(){
    animVolume(100);
  }).on('mouseup', function(){
    if( volObj.tween )
      volObj.tween.kill();
  }).on('keydown', function(e){
    if(e.which==13 || e.which==32){
      e.preventDefault();
      animVolume(100);
    }
  }).on('keyup', function(e){
    if(e.which==13 || e.which==32){
      e.preventDefault();
      if( volObj.tween )
        volObj.tween.kill();
    }
  });
  // listen for window.resize
  window.onresize = onResize;
};

var togglePlayPause = function($el){
  if( $el.hasClass('kdnze-controls-play') ) {
    onPlayClick();
  } else {
    onPauseClick();
  }
};

var animVolume = function( to ){
  if(volObj.volume!=to){
    //            var t = (volObj.volume /volObj.speed);
    var t = Math.abs(volObj.volume-to) * (volObj.speed/100);
    volObj.tween = TweenLite.to( volObj, t, {
      volume: to,
      onUpdate: function(){
        $('#ap-volume-slider').slider('value', volObj.volume );
      } });
  }
};

// Cache for compressed URLs
var compressedCache = {};

// Process audio based on size (15MB threshold) and playability
var processAudio = function(fileUrl, fileSize, $track, callback) {
  if (compressedCache[fileUrl]) {
    console.log('Using cached audio:', fileUrl);
    callback(compressedCache[fileUrl]);
    return;
  }

  const sizeThreshold = 15 * 1024 * 1024; // 15MB threshold

  if (fileSize > sizeThreshold) {
    console.log(`File is large (${(fileSize / 1024 / 1024).toFixed(2)} MB), compressing:`, fileUrl);
    $track.text('Compressing large file...'); // Show message to user
    compressAudioWebAudio(fileUrl, function(compressedUrl) {
      compressedCache[fileUrl] = compressedUrl;
      callback(compressedUrl);
    });
  } else {
    console.log(`File is small (${(fileSize / 1024 / 1024).toFixed(2)} MB), using original:`, fileUrl);
    callback(fileUrl); // Fallback to original if small
  }
};

// Simplified compression function
var compressAudioWebAudio = function(fileUrl, callback) {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  fetch(fileUrl)
    .then(response => response.arrayBuffer())
    .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
    .then(audioBuffer => {
      const targetSampleRate = 11025; // Low sample rate for speed
      const offlineContext = new OfflineAudioContext(
        1, // Mono for faster processing
        audioBuffer.length * (targetSampleRate / audioBuffer.sampleRate),
        targetSampleRate
      );

      const source = offlineContext.createBufferSource();
      source.buffer = audioBuffer;

      // Convert to mono
      const merger = offlineContext.createChannelMerger(1);
      source.connect(merger);
      merger.connect(offlineContext.destination);
      source.start();

      offlineContext.startRendering().then(renderedBuffer => {
        const wavBlob = bufferToWave(renderedBuffer);
        const compressedUrl = URL.createObjectURL(wavBlob);
        callback(compressedUrl);
      }).catch(err => {
        console.error('Rendering failed:', err);
        callback(fileUrl);
      });
    })
    .catch(err => {
      console.error('Fetching or decoding failed:', err);
      callback(fileUrl);
    });
};

// Same bufferToWave function
function bufferToWave(abuffer) {
  const numOfChan = abuffer.numberOfChannels;
  const length = abuffer.length * numOfChan * 2 + 44;
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);
  const channels = [];
  let offset = 44;

  view.setUint32(0, 0x46464952, true); // "RIFF"
  view.setUint32(4, length - 8, true);
  view.setUint32(8, 0x45564157, true); // "WAVE"
  view.setUint32(12, 0x20746D66, true); // "fmt "
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, numOfChan, true);
  view.setUint32(24, abuffer.sampleRate, true);
  view.setUint32(28, abuffer.sampleRate * 2 * numOfChan, true);
  view.setUint16(32, numOfChan * 2, true);
  view.setUint16(34, 16, true);
  view.setUint32(36, 0x61746164, true); // "data"
  view.setUint32(40, length - 44, true);

  for (let i = 0; i < numOfChan; i++) {
    channels.push(abuffer.getChannelData(i));
  }
  for (let i = 0; i < abuffer.length; i++) {
    for (let channel = 0; channel < numOfChan; channel++) {
      const sample = Math.max(-1, Math.min(1, channels[channel][i]));
      view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
      offset += 2;
    }
  }

  return new Blob([buffer], { type: 'audio/wav' });
}

// Updated createTrack with message handling
var createTrack = function(okObj, startImmediately) {
  if (startImmediately) {
    currentTrack = okObj.id;
  }

  if (!playlistDict[okObj.id]) {
    var originalUrl = okObj.m4a_url || okObj.file.url || okObj.file.file.url;
    var fileSize = okObj.size || 0;

    var $track = $('<li class=\'track-' + okObj.id + '\'></li>').appendTo('#ap-playlist');
    $track.text('Processing...');

    processAudio(originalUrl, fileSize, $track, function(finalUrl) {
      $track.empty(); // Clear "Processing..." or "Compressing large file..."

      var jsObj = {
        id: okObj.id,
        soundRef: soundManager.createSound({
          id: okObj.id,
          url: finalUrl,
          autoLoad: true,
          autoPlay: false,
          onload: function() {
            switch (this.readyState) {
              case 1: console.log('file loading'); break;
              case 2: console.log('file load failed'); break;
              case 3:
                console.log('file success');
                var time = msToTime(this.duration);
                $('li.track-' + okObj.id + ' .ap-track-duration').text(timeDisplay(time));
                break;
              case 0: console.log('SoundManager uninitialised');
            }
          },
          whileloading: function() {
            console.log(this.bytesLoaded);
            console.log(this.bytesTotal);
          }
        }),
        name: okObj.name,
        full_name: okObj.user_name
      };

      console.log('Can Play?', soundManager.canPlayURL(jsObj.soundRef.url), jsObj.soundRef.url);

      playlistDict[jsObj.id] = jsObj;
      if (!currentTrack) {
        currentTrack = jsObj.id;
      }
      var trackTitle = jsObj.name;
      if (jsObj.full_name) {
        trackTitle += ' — ' + jsObj.full_name;
      }

      $('<input>').attr({ type: 'hidden', name: jsObj.id }).appendTo($track);
      $('<div class=\'ap-track-play-indicator\'></div>').appendTo($track);
      $('<div class=\'ap-track-title\'></div>').html(trackTitle).appendTo($track);
      $('<div class=\'ap-track-duration\'></div>').text('--:--').appendTo($track);
      var $trackDelete = $('<div class=\'ap-track-delete\'><a class=\'kdnze-x\' href=\'javascript:void(0)\' aria-label=\'Delete Track\'></div>').appendTo($track);

      $track.on('click', function() {
        playTrack(jsObj.id);
      });

      $trackDelete.on('click', function() {
        jsObj.soundRef.unload();
        playlistDict[jsObj.id] = null;
        $track.remove();
        if (currentTrack == jsObj.id) {
          resetPlayer();
        }
      });

      if (startImmediately) {
        playTrack(currentTrack);
      }
    });
  } else if (startImmediately) {
    playTrack(currentTrack);
  }
};

var playTrack = function( trackID ) {
  if( !playlistDict[currentTrack] )
    return;

  $playBtn.removeClass('kdnze-controls-play');
  $playBtn.addClass('kdnze-controls-pause');
  // reset progress slider
  $('#ap-progress-slider').slider({ value: 0 });

  currentTrack = trackID;
  var trackInfo = playlistDict[trackID];
  // set play text
  if (trackInfo.full_name) {
    $('.ap__title_bar').html(trackInfo.full_name + ' &mdash; '+ trackInfo.name);
  }
  else {
    $('.ap__title_bar').html(trackInfo.name);
  }

  // if playing stop all...
  soundManager.stopAll();
  // find dom element with name matching trackID
  // and set the parent to playing
  $('#ap-playlist').find('li').removeClass('playing');
  var $h_id = $('input[name='+trackID+']');
  $h_id.parent().addClass('playing');

  var sound = trackInfo.soundRef;
  sound.setVolume(currentVolume);

  soundManager.play( sound.id, {
    whileplaying: onPlayProgress,
    onfinish: onPlayFinished
  });
};

var prevTrack = function() {
  if( !playlistDict[currentTrack] )
    return;

  var $old_track = $('input[name='+currentTrack+']').parent();
  var previousID = $old_track.prev().find('input').attr('name');
  if( previousID ) {
    $old_track.removeClass('playing');
    playTrack( previousID );
  } else {
    playTrack( currentTrack );
  }
};

var nextTrack = function() {
  if( !playlistDict[currentTrack] )
    return;

  var trackInfo = playlistDict[currentTrack];
  var $old_track = $('input[name='+currentTrack+']').parent();
  var nextID = $old_track.next().find('input').attr('name');
  if( nextID ) {
    playTrack( nextID );
  } else {
    // check if current track is stopped, reset player
    if( playlistDict[currentTrack].soundRef.playState === 0 ) {
      resetPlayer();
    }
  }
};

var resetPlayer = function() {
  // show play button
  $playBtn.removeClass('kdnze-controls-pause');
  $playBtn.addClass('kdnze-controls-play');
  // get list item of last track played
  var $old_track = $('input[name='+currentTrack+']').parent();
  // set currentTrack to the first track and deselect last track played
  currentTrack = $('#ap-playlist li:first').find('input').attr('name');
  $old_track.removeClass('playing');
  // reset progress slider
  $('#ap-progress-slider').slider({ value: 0 });
  // remove title and name
  $('.ap-username').text( '' );
  $('.ap-filename').text( '' );
  // reset play counter
  $('#ap-current-time').text('0:00');
  $('#ap-remaining-time').text('-0:00');
};

var togglePlaylist = function() {
  if( panelState == PANEL_MINIMIZED ) {
    openPanel();
  } else {
    minimizePanel();
  }
};

var toggleHidePlayer = function() {
  if( panelState == PANEL_HIDDEN ) {
    if( previousState == PANEL_OPEN )
      openPanel();
    if( previousState == PANEL_MINIMIZED )
      minimizePanel();
  } else {
    hidePanel();
  }
};

// EVENT HANDLERS

var onPlayFinished = function( e ) {
  currentTrack = this.id;
  nextTrack();
};

var onPlayProgress = function( e ) {
  var mPosition = moment(0, 'm:ss').milliseconds( this.position );
  $('#ap-current-time').text( mPosition.format('m:ss') );

  var mDuration = moment(0, 'm:ss').milliseconds( this.duration );
  if( mDuration && mPosition ) {
    var mRemaining = moment(0, 'm:ss').milliseconds(mDuration.diff(mPosition));
    $('#ap-remaining-time').text('-'+ mRemaining.format('m:ss') );
    var progressVal = this.position/this.duration;
    $( '#ap-progress-slider' ).slider({ value: progressVal * 100 });
  }
};

var onPlayClick = function() {
  if( !playlistDict[currentTrack] )
    return;

  $playBtn.removeClass('kdnze-controls-play');
  $playBtn.addClass('kdnze-controls-pause');

  if(playlistDict[currentTrack].soundRef.paused) {
    soundManager.resume(currentTrack);
  } else {
    playTrack(currentTrack);
  }
};

var onPauseClick = function() {
  $playBtn.removeClass('kdnze-controls-pause');
  $playBtn.addClass('kdnze-controls-play');
  soundManager.pauseAll();
};

var onResize = function( e ) {
  var currentOffset = mobileHeightOffSet;
  mobileHeightOffSet = ($(this).width() < 768 )? 53 : 0;
  // if size changes from desktop to mobile recall panel actions
  if( currentOffset != mobileHeightOffSet ) {
    if(panelState == PANEL_OPEN )
      $self.css('bottom', 360 + 'px');
    if(panelState == PANEL_MINIMIZED )
      $self.css('bottom', 140 + 'px');
  }

  // this sets a max width to preserve the right margin of the slider range bar
  var volRangeWidth = $('#ap-volume-slider').width() - 6;
  var progressRangeWidth = $('#ap-progress-slider').width() - 6;
  $('#ap-progress-slider .ui-slider-range').css( 'max-width',  progressRangeWidth + 'px' );
  $('#ap-volume-slider .ui-slider-range').css( 'max-width', volRangeWidth + 'px' );
};

// PANEL ACTIONS

var closePanel = function() {
  previousState = panelState;
  $('.ap-minbar').removeClass('minimized');
  panelState = PANEL_CLOSED;
  $self.removeClass('shown');
  a.to($self, 0.5, { bottom:-50 });
};

var hidePanel = function() {
  previousState = panelState;
  $('.ap-minbar').addClass('minimized');
  panelState = PANEL_HIDDEN;
  $self.find('a').attr('tabindex','-1');
  $self.find('a.ap-minbar').attr('tabindex','');
  TweenLite.to($self, 0.5, { bottom:0 });
};

var openPanel = function() {
  previousState = panelState;
  $('.ap-minbar').removeClass('minimized');
  panelState = PANEL_OPEN;
  TweenLite.to($self, 0.5, { bottom: 340 + mobileHeightOffSet, ease:Quad.easeOut });
};

var minimizePanel = function() {
  previousState = panelState;
  $('.ap-minbar').removeClass('minimized');
  panelState = PANEL_MINIMIZED;
  $self.addClass('shown');
  $self.find('a').attr('tabindex','');
  $self.find('a.ui-slider-handle').attr('tabindex','-1');
  TweenLite.to($self, 0.5, { bottom: 72 + mobileHeightOffSet, ease:Quad.easeOut });
};


// https://gist.github.com/remino/1563878
var msToTime = function(ms) {
  var d, h, m, s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s = s % 60;
  h = Math.floor(m / 60);
  m = m % 60;
  d = Math.floor(h / 24);
  h = h % 24;
  return { days: d, hours: h, minutes: m, seconds: s };
};

var padDigits = function(num) {
  if (num <= 9) {
    return '0' + num;
  }
  return num.toString();
};

var timeDisplay = function(timeObj) {
  var ret = [];
  if (timeObj.hours > 0) {
    ret.push(padDigits(timeObj.hours));
  }
  ret.push(padDigits(timeObj.minutes));
  ret.push(padDigits(timeObj.seconds));

  return ret.join(':');
};

// PUBLIC FUNCTIONS

export default {
  init : function(callback) {
    console.log('AudioPlayer Initialized');
    $self = $(JST['templates/audio-player']()).prependTo('#main-container');
    initUI();
    initSoundManager(callback);
    playerInitialized = true;
  },
  createSoundManager : function(callback) {
    return initSoundManager(callback);
  },
  addToQueue : function( koObj  ) {
    if( panelState != PANEL_OPEN )
      minimizePanel() && openPanel();
    createTrack( koObj, false );
    return 'adding track';
  },
  play: function( koObj ) {
    if( panelState == PANEL_CLOSED || panelState == PANEL_HIDDEN )
      minimizePanel();
    createTrack( koObj, true );
    $('.ap-play').focus();
    return 'playing track';
  },
  open : function() {
    if( panelState != PANEL_OPEN) {
      openPanel();
    }
    return panelState;
  },
  close : function() {
    if( panelState != PANEL_CLOSED) {
      closePanel();
    }
    return panelState;
  },
  mute: function() {

  },
  unmute : function() {

  },
  minimize : function() {
    if( panelState != PANEL_MINIMIZED) {
      minimizePanel();
    }
    return panelState;
  },
  initialized: function() {
    return playerInitialized;
  }
};
